<template>
  <v-container>
    <span class="text-h4">Accesos</span>
    <v-divider></v-divider>
    <v-row
      ><v-col>
        <AccesosFilter
          ref="AccesosFilter"
          @accesos-changed="sendAccesos"
          :pageNumber="pageNumber"
          :search="this.documento"
        >
        </AccesosFilter> </v-col
    ></v-row>
    <v-row>
      <v-col>
        <AccesosTable
          ref="accesosTable"
          :accesos="this.accesos.results"
          :accesosCount="this.accesos.count"
          @egreso-set="$refs.AccesosFilter.doFilter()"
          @paginate="handlePagination"
        >
        </AccesosTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AccesosTable from "../components/AccesosTable";
import AccesosFilter from "../components/AccesosFilter.vue";

export default {
  name: "Accesos",
  components: {
    AccesosTable,
    AccesosFilter,
  },
  data() {
    return {
      accesos: [],
      pageNumber: "1",
    };
  },
  props: ["documento"],
  methods: {
    sendAccesos(accesos) {
      this.$refs.accesosTable.loading = false;
      this.accesos = accesos;
    },
    handlePagination(pageNumber) {
      this.pageNumber = pageNumber;
    },
  },
};
</script>
