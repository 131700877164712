<template>
  <div v-if="accesos">
    <!-- Dialog -->
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="490">
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Marcar Egreso
          </v-btn>
        </template> -->
        <v-card>
          <v-card-title class="text-h5"> Marcar Egreso </v-card-title>
          <v-card-text v-if="this.currentItem"
            >Está a punto de definir el egreso de
            {{ this.currentItem.persona.nombres }}
            {{ this.currentItem.persona.npellidos }}, con documento número
            {{ this.currentItem.persona.documento_nro }}
            <br />

            <div v-if="checkElementos(this.currentItem)">
              <p>Comprobar Elementos</p>
              <v-form>
                <v-checkbox
                  v-for="(elemento, index) in elementosFilter"
                  :key="index"
                  ref="checks"
                  v-model="elemento.checked"
                  :label="
                    elemento.descripcion +
                    ' - Serie Nº: ' +
                    elemento.numero_serie
                  "
                ></v-checkbox>
              </v-form>
            </div>

            <br />
            ¿Continuar?
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click="dialog = false">
              Cancelar
            </v-btn>
            <v-btn
              color="primary darken-1"
              text
              :disabled="!isEnabled"
              @click="btnAcceptEgresoClick()"
            >
              Aceptar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Table -->
    <v-data-table
      :headers="headers"
      :items="accesos"
      item-key="id"
      class="elevation-1"
      locale="es-AR"
      no-data-text="No hay datos para mostrar"
      :header-props="headerProps"
      :footer-props="footerProps"
      mobile-breakpoint="500"
      @click:row="selectRow"
      disable-sort
      :server-items-length="accesosCount"
      hide-default-footer
      :page.sync="page"
      :loading="loading"
      loading-text="Cargando... Espere por favor."
      @update:page="pageUpdateFunction"
    >
      <template v-slot:item.egreso="{ item }">
        <v-btn
          rounded
          small
          color="success"
          v-if="!item.egreso"
          @click.stop="btnEgresoClick(item)"
          :key="item.key"
        >
          Marcar</v-btn
        >
        <div v-else>{{ item.egreso }}</div>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination
        v-model="page"
        :length="pageCount"
        :total-visible="15"
        circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "AccesosTable",
  data() {
    return {
      search: "",
      dialog: false,
      currentItem: null,
      elementosCheck: [],
      headerProps: {
        sortByText: "Ordenar por",
      },
      footerProps: {
        itemsPerPageText: "Filas por página",
      },
      itemsPerPage: 10,
      page: 1,
      loading: false,
    };
  },
  created() {
    // this.fetchItems();
  },
  props: {
    accesos: {
      type: Array,
      required: false,
    },
    accesosCount: {
      type: Number,
      required: false,
    },
  },
  methods: {
    selectRow(acceso) {
      this.$router.push({
        name: "AccesoDetalle",
        params: { id: acceso.id },
      });
    },

    pageUpdateFunction(newPageNumber) {
      this.loading = true;
      //this.accesos = [];
      this.$emit("paginate", newPageNumber.toString());
    },

    // Comprueba si hay al menos un elemento para Control
    checkElementos(item) {
      for (let i = 0; i < item.elementos.length; i++)
        if (item.elementos[i].controlar) return true;
      return false;
    },
    checkElementosCheckboxs() {
      var countTrue = 0;

      for (let i = 0; i < this.elementosFilter.length; i++)
        if (this.elementosFilter[i].checked) countTrue++;
      if (countTrue == this.elementosFilter.length) return true;
      else return false;
    },

    btnEgresoClick(item) {
      this.dialog = true;
      this.currentItem = item;
    },
    btnAcceptEgresoClick() {
      VisitasService.setEgreso(this.currentItem.id)
        .then((response) => {
          // this.fetchItems();
          this.$emit("egreso-set");
        })
        .catch((error) => console.log(error));
      this.dialog = false;
    },
  },
  computed: {
    pageCount: function () {
      if (this.accesosCount < 10) return 1;
      return Math.ceil(this.accesosCount / this.itemsPerPage);
    },
    isEnabled() {
      return this.checkElementosCheckboxs();
    },
    elementosFilter() {
      let elementosFiltrados = [];
      if (!this.currentItem) return [];
      for (let i = 0; i < this.currentItem.elementos.length; i++)
        if (this.currentItem.elementos[i].controlar)
          elementosFiltrados.push(this.currentItem.elementos[i]);
      return elementosFiltrados;
    },
    headers() {
      return [
        {
          text: "Apellidos",
          align: "start",
          sortable: true,
          value: "persona.apellidos",
        },
        {
          text: "Nombres",
          align: "left",
          sortable: true,
          value: "persona.nombres",
        },

        {
          text: "Documento Tipo",
          align: " left d-none d-md-table-cell",
          sortable: true,
          value: "persona.get_documento_tipo_display",
        },
        {
          text: "Documento Nº",
          align: "left d-none d-md-table-cell",
          sortable: true,
          value: "persona.documento_nro",
        },
        {
          text: "Ingreso",
          align: "left",
          sortable: true,
          value: "ingreso",
        },
        {
          text: "Egreso",
          align: "left",
          sortable: true,
          value: "egreso",
        },
      ];
    },
  },
};
</script>
