<template>
  <div>
    <v-expansion-panels flat tile>
      <v-expansion-panel>
        <v-expansion-panel-header>Filtro</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form @submit.prevent="onSubmit">
            <v-row>
              <v-col cols="6" md="2">
                <v-menu
                  ref="menu_from"
                  v-model="menu_from"
                  :close-on-content-click="false"
                  :return-value.sync="date_from"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_from"
                      label="Desde"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-AR"
                    v-model="date_from"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu_from = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_from.save(date_from)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" md="2">
                <v-menu
                  ref="menu_to"
                  v-model="menu_to"
                  :close-on-content-click="false"
                  :return-value.sync="date_to"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_to"
                      label="Hasta"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    locale="es-AR"
                    v-model="date_to"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="menu_to = false">
                      Cancelar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.menu_to.save(date_to)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field v-model="search" label="Texto"></v-text-field>
              </v-col>
              <v-col cols="4" md="2">
                <v-select
                  :items="tipos"
                  item-text="nombre"
                  item-value="id"
                  label="Tipo Persona"
                  value="5"
                  v-model="tipoSeleccion"
                ></v-select>
              </v-col>
              <v-col cols="5" md="2">
                <v-switch v-model="sinEgreso" label="Sin Egreso"></v-switch>
              </v-col>
              <v-col cols="2" md="1" class="col-btn-filter">
                <v-btn
                  color="secondary"
                  icon
                  dark
                  bottom
                  @click="doFilter(true)"
                  type="submit"
                  ><v-icon>mdi-text-search</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import VisitasService from "@/services/VisitasService.js";

export default {
  name: "AccesosFilter",
  data() {
    return {
      tipos: [],
      tipoSeleccion: 0,
      sinEgreso: false,
      accesos: [],
      date_from: null,
      date_to: null,
      menu_from: null,
      menu_to: null,
    };
  },
  props: {
    pageNumber: {
      type: String,
      required: false,
    },
    search: {
      type: String,
      required: false,
    },
  },
  created() {
    VisitasService.getTiposPersona()
      .then((response) => {
        this.tipos = response.data;
        this.tipos.push({ id: 0, nombre: "Todos" });
        this.doFilter();
      })
      .catch((error) => console.log(error));
    this.date_from = new Date();
    this.date_to = new Date();
    this.date_to.setDate(this.date_from.getDate() + 1);
    this.date_from = this.date_from.toISOString().slice(0, 10);
    this.date_to = this.date_to.toISOString().slice(0, 10);
    // this.date_from = new Date(
    //   Date.now() - new Date().getTimezoneOffset() * 60000
    // )
    //   .toISOString()
    //   .substr(0, 10);
    // this.date_to = new Date(
    //   Date.now() + new Date().getTimezoneOffset() * 600000
    // )
    //   .toISOString()
    //   .substr(0, 10);
  },
  methods: {
    doFilter(resetPage = false) {
      let page = "1";
      if (resetPage) page = 1;
      else page = this.pageNumber;
      VisitasService.getAccesos(
        this.date_from,
        this.date_to,
        this.search,
        this.tipoSeleccion,
        this.sinEgreso,
        page
      )
        .then((response) => {
          this.accesos = response.data;
          this.$emit("accesos-changed", this.accesos);
        })
        .catch((error) => console.log(error));
    },
  },
  watch: {
    pageNumber: function (newVal, oldVal) {
      // watch it
      if (newVal && newVal.length) this.doFilter();
    },
  },
};
</script>

<style>
.col-btn-filter {
  padding-top: 25px;
}
</style>
